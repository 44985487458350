"use client";

import { useIncidentFilter } from "@/hooks/useIncidentFilter";
import { useUpdateIncidentFilter } from "@/hooks/useUpdateIncidentFilter";
import { PlaceOutlined, Search } from "@mui/icons-material";
import { CircularProgress, Stack, TextField } from "@mui/material";
import { useRouter } from "next/navigation";
import { FormProvider, useForm } from "react-hook-form";
import { IncidentPresetSelect } from "@/components/IncidentPresetSelect";
import { useUpdateEffect } from "@/hooks/useUpdateEffect";
import { DateModalButton } from "./DateModalButton";

type Props = {
  loading?: boolean;
};

export function IncidentFilterForm({ loading, ...props }: Props) {
  const router = useRouter();
  const { data: incidentFilter } = useIncidentFilter();

  const form = useForm({
    defaultValues: {
      keywords: incidentFilter?.keywords || "",
      location: incidentFilter?.location || "",
      presets: incidentFilter?.presets || [],
    },
  });

  const updateIncidentFilter = useUpdateIncidentFilter();
  const [keywords, location, presets] = form.watch([
    "keywords",
    "location",
    "presets",
  ]);

  useUpdateEffect(() => {
    updateIncidentFilter.mutate({
      presets,
    });
  }, [presets]);

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit((data) => {
          updateIncidentFilter.mutate(data);

          const newSearchParams = new URLSearchParams({
            keywords,
            location,
          });

          router.replace(`?${newSearchParams.toString()}`);
        })}
      >
        <Stack
          sx={{
            flexDirection: "row",
            margin: "1rem 0",
            gap: 4,
          }}
        >
          <TextField
            label="Search"
            disabled={loading}
            sx={{
              flex: 0.4,
            }}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{
                    marginRight: "0.2rem",
                  }}
                />
              ),

              endAdornment: loading ? <CircularProgress size="2rem" /> : null,

              ...form.register("keywords"),
            }}
          />

          <TextField
            label="Location"
            placeholder="Search by city, county, state, zip code"
            disabled={loading}
            sx={{
              flex: 0.7,
            }}
            InputProps={{
              startAdornment: (
                <PlaceOutlined
                  sx={{
                    marginRight: "0.2rem",
                  }}
                />
              ),

              endAdornment: loading ? <CircularProgress size="2rem" /> : null,

              ...form.register("location"),
            }}
          />

          {/* <TextField
          label='Filters'
          disabled

          sx={{
            flex: 1,
          }}

          InputProps={{
            startAdornment: <PlaceOutlined
              sx={{
                marginRight: '0.2rem',
              }}
            />,

            endAdornment: loading
              ? <CircularProgress
                size='2rem'
              />
              : null,
          }}
        /> */}
          <Stack
            sx={{
              flex: 0.6,
              width: 0,
            }}
          >
            <IncidentPresetSelect name="presets" />
          </Stack>

          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DateModalButton />
          </Stack>

          <Stack
            component="input"
            type="submit"
            sx={{
              display: "none",
            }}
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
