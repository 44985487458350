"use client";
import { api } from "@/api";
import type { Incident, IncidentFilter } from "@/types";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  InfiniteData,
} from "@tanstack/react-query";

type Response = {
  total: number;
  incidents: Incident[];
};

export function useIncidents(
  { filter }: { filter?: IncidentFilter },
  options?: Partial<UseInfiniteQueryOptions<Response, Error, InfiniteData<Response>>>
) {
  const PAGE_SIZE = 100;

  return useInfiniteQuery<Response, Error, InfiniteData<Response>>({
    queryKey: ["incidents", { filter }],
    initialPageParam: 0,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    queryFn: async ({ pageParam: page, signal }) => {
      const {
        datesRelativeTo,
        creator,
        comments,
        latitude,
        longitude,
        radius,
        incidentStatus,
        category,
        from,
        to,
        keywords,
        legal,
        location,
        presets,
        full,
        active,
        line1,
        line2,
        city,
        county,
        state,
        incidentId,
        alarm,
        propertyAddress,
        legalDetails,
        stateIds,
        countyInclusions,
        countyExclusions,
        cityInclusions,
        cityExclusions,
        allCountyStateIds,
      } = filter ?? {};

      const params = {
        datesRelativeTo,
        creator,
        comments,
        latitude,
        longitude,
        radius,
        incidentStatus,
        category,
        limit: PAGE_SIZE,
        offset: page * PAGE_SIZE,
        keywords,
        legal,
        location,
        presets,
        from,
        to,
        full,
        active,
        line1,
        line2,
        city,
        county,
        state,
        incidentId,
        alarm,
        propertyAddress,
        legalDetails,
        stateIds,
        countyInclusions,
        countyExclusions,
        cityInclusions,
        cityExclusions,
        allCountyStateIds,
      };

      // Clean up undefined values and convert booleans
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined) {
          delete (params as any)[key];
        } else if (typeof value === "boolean") {
          (params as any)[key] = value ? "1" : "";
        }
      });

      const response = await api.get<Response>("incidents", {
        params,
        signal,
      });

      return response.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.incidents.length < PAGE_SIZE) {
        return undefined;
      }
      return allPages.length;
    },
    ...options,
  });
}