"use client";

import { IncidentCard } from "@/components/IncidentCard";
import { IncidentCardCondensed } from "./IncidentCardCondensed";
import { useIncidentFilter } from "@/hooks/useIncidentFilter";
import { useIncidents } from "@/hooks/useIncidents";
import { Incident } from "@/types";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSelf } from "@/hooks/useSelf";

type PageType = {
  success: boolean;
  incidents: Incident[];
  total: number;
}
type InfiniteResponseType = {
  pages: PageType[]
  pageParams: number[];
}

export function IncidentsList() {
  const { ref: bottomRef, inView: hasReachedBottom } = useInView();
  const { data: incidentFilter } = useIncidentFilter();

  const { data: self } = useSelf();

  const isSuperAdmin = self?.Roles?.some((role) => role.id === "superAdmin"); 

  const {
    data: infiniteIncidents,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useIncidents(
    {
      filter: {
        active: isSuperAdmin ? true: true,
        limit: 10,
        keywords: incidentFilter?.keywords || "",
        location: incidentFilter?.location || "",
        presets: incidentFilter?.presets || [],
        from: incidentFilter?.from || "",
        to: incidentFilter?.to || "",
        incidentStatus: incidentFilter?.incidentStatus || "active",
        propertyAddress: incidentFilter?.propertyAddress || true,
        legalDetails: incidentFilter?.legalDetails || false,
        stateIds: incidentFilter?.stateIds || [],
        countyInclusions: incidentFilter?.countyInclusions || [],
        countyExclusions: incidentFilter?.countyExclusions || [],
        cityInclusions: incidentFilter?.cityInclusions || [],
        cityExclusions: incidentFilter?.cityExclusions || [],
        allCountyStateIds: incidentFilter?.allCountyStateIds || [],
      },
    },
    {
      placeholderData: keepPreviousData,
      staleTime: 20 * 1000,
    },
  );

  const incidents = useMemo(() => {
    if (!infiniteIncidents?.pages?.length) {
      console.log("No infinite incidents data");
      return [];
    }

    return infiniteIncidents.pages.flatMap((page) => {
      if (!page?.incidents?.length) {
        console.log("No incidents in page", page);
        return [];
      }
      return page.incidents;
    });
  }, [infiniteIncidents]) as Incident[];

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  useEffect(() => {
    if (hasReachedBottom) {
      fetchNextPage();
    }
  }, [hasReachedBottom]);

  if (isLoading) {
    return (
      <Stack
        sx={{
          flex: 1,
        }}
      >
        <CircularProgress
          sx={{
            margin: "auto",
          }}
        />
      </Stack>
    );
  }

  if (!incidents.length) {
    return (
      <Stack
        sx={{
          flex: 1,
        }}
      >
        <Typography
          sx={{
            margin: "auto",
          }}
        >
          {error
            ? error.message
            : "There are no incidents matching this criteria"}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      component="ol"
      sx={{
        gap: 2,
      }}
    >
      {incidents.map((incident) => (
        <Stack key={incident.id} component="li">
          <IncidentCardCondensed incident={incident} self={self} />
        </Stack>
      ))}
      <Stack ref={bottomRef}>
        {isFetchingNextPage ? (
          <CircularProgress
            sx={{
              margin: "auto",
            }}
          />
        ) : null}
      </Stack>
    </Stack>
  );
}
