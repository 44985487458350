"use client";
import React, { useMemo } from "react";
import { dayjs } from "@/util/dayjs";
import { Typography, TypographyProps } from "@mui/material";
import { useSelf } from "@/hooks/useSelf";
import { useSystemInfo } from "@/context/system-info";

interface TimeDisplayProps extends Omit<TypographyProps, "title"> {
  timestamp: string | Date | undefined;
  format?: string;
  debugMode?: boolean;
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({
  timestamp,
  format,
  variant,
  debugMode = process.env.NODE_ENV === "development",
  ...typographyProps
}) => {
  const { data: self } = useSelf();
  const { timezone } = useSystemInfo();
  const isStaff = self?.Roles?.[0]?.isStaff ?? false;

  const { time, debugTitle } = useMemo(() => {
    try {
      if (!timestamp) {
        return { time: null, debugTitle: undefined };
      }

      let formattedTime;
      try {
        if (isStaff) {
          formattedTime = dayjs(timestamp).tz("America/Los_Angeles");
        } else {
          formattedTime = timezone ? dayjs(timestamp).tz(timezone) : dayjs(timestamp).utc();
        }
        if (!formattedTime.isValid()) {
          throw new Error("Invalid time");
        }
      } catch (e) {
        console.warn(`Timezone conversion failed, using UTC: ${e}`);
        formattedTime = dayjs(timestamp).utc();
      }

      const debugInfo = debugMode
        ? `Staff: ${isStaff}\nLocal: ${dayjs(timestamp).format(
            "HH:mm:ss"
          )}\nPST: ${dayjs(timestamp)
            .tz("America/Los_Angeles")
            .format("HH:mm:ss")}`
        : undefined;

      return { time: formattedTime, debugTitle: debugInfo };
    } catch (error) {
      console.error("Error in TimeDisplay:", error);
      return {
        time: dayjs(timestamp).utc(),
        debugTitle: `Error: Using UTC time`,
      };
    }
  }, [timestamp, isStaff, timezone, debugMode]);

  if (!time || !time.isValid()) {
    return null;
  }

  return (
    <Typography
      variant={variant || "caption"}
      color="text.secondary"
      title={debugTitle}
      {...typographyProps}
    >
      {time.format(format || "MMM D, YYYY h:mm A")}
    </Typography>
  );
};