"use client";

import StarIcon from "@/assets/star.svg";
import { useIncidentFilter } from "@/hooks/useIncidentFilter";
import { Incident, User } from "@/types";
import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { Star } from "@mui/icons-material";
import { StructureTypeIcon } from "@/components/StructureTypeIcon";
import { theme } from "@/util/theme";
import Link from "next/link";
import { TimeDisplay } from "./ui/time-display";

type Props = {
  id?: string;
  incident?: Incident;
  disabled?: boolean;
  self?: { data: User | null };
};

export const IncidentCardCondensed = ({
  incident,
  disabled,
  self,
  ...props
}: Props) => {
  const { data: incidentFilter } = useIncidentFilter();
  const isStaff = self?.data?.Roles?.[0]?.isStaff ?? false;


  return (
    <Stack
      component={Link}
      href={
        isStaff
          ? `/incident?incidentId=${incident?.id}`
          : `/incidents/${incident?.id}`
      }
      prefetch={false}
      sx={{
        flex: 1,
        boxShadow: 1,
        padding: "0.7rem",
        paddingBottom: "0.3rem",
        borderRadius: "16px",
        backgroundColor: "#FFFFFF",
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", width: "33.3%" }}>
          <Stack
            sx={{
              width: "52px",
              height: "52px",
              borderRadius: "9999px",
              backgroundColor: "#F4F5F7",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "1rem",
            }}
          >
            <StructureTypeIcon
              style={{
                width: "24px",
                // @ts-ignore
                color: theme.colors.damage[incident?.Type?.DamageType.id!],
              }}
            >
              {incident?.StructureTypeId}
            </StructureTypeIcon>
          </Stack>
          <Stack
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
              }}
            >
              {incident?.StructureType.name}
            </Typography>

            <Stack
              sx={{
                borderRadius: "8px",

                backgroundColor:
                  // @ts-ignore
                  theme.colors.damage[incident?.Type?.DamageType.id!],
                width: "max-content",
                padding: "0 0.6rem",
                minHeight: "23px",
                marginTop: "0.2rem",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  margin: "auto",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "0.8rem",
                }}
              >
                {incident?.Type.name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ width: "33.3%" }}>
          <Typography>
            <Typography
              component="span"
              sx={{
                fontWeight: "700",
                marginRight: 1,
              }}
            >
              Address:
            </Typography>{" "}
            {incident?.Address.full}
          </Typography>

          <Typography>
            <Typography
              component="span"
              sx={{
                fontWeight: "700",
                marginRight: 2,
              }}
            >
              County:
            </Typography>{" "}
            {incident?.Address.County.name}
          </Typography>
        </Stack>

        <Stack sx={{ width: "33.3%" }}>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                textAlign: "right",
              }}
            >
              {incident?.id}
            </Typography>

            {incident?.favorited ? (
              <Star
                sx={{
                  color: "#FFC107",
                  width: "16px",
                  height: "16px",
                  marginTop: "0.1rem",
                  marginLeft: "0.3rem",
                }}
              />
            ) : null}
          </Stack>

          <Stack spacing={0.5}>
            <TimeDisplay
              timestamp={incident?.startedAt}
              format="h:mm A"
              sx={{
                textAlign: "right",
                fontSize: "0.9rem",
                whiteSpace: "nowrap",
                marginLeft: "1rem",
              }}
              component="div"
            />

            <TimeDisplay
              timestamp={incident?.startedAt}
              format="MM-DD-YY"
              sx={{
                textAlign: "right",
                fontSize: "0.9rem",
              }}
              component="div"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
